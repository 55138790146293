import ResourceBase from "@/resources/ResourceBase";
import GenericResponse from "@/types/GenericResponse";
import PagedData from "@/types/PagedData";
import PagedComments from "@/types/PagedComments";
import Issue from "@/types/Issue";
import Reporter from "@/types/Reporter";
import IssueWatcher from "@/types/IssueWatcher";
import { IssueStatus } from "@/types/IssueStatus";
import IssueDevicesSummary from "@/types/IssueDevicesSummary";
import Comment from "@/types/Comment";
import IssueDeviceListItem from "@/types/IssueDeviceListItem";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import IssueDevicesStats from "@/types/IssueDevicesStats";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { ArchivedIssueFilter } from "@/types/ArchivedIssueFilter";

class IssueResource extends ResourceBase {
    addIssue(name: string, deviceIds?: number[], cancelToken?: any): Promise<AxiosResponse<Issue>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<Issue>("/issues", { name, deviceIds }, config);
    }

    getIssuesPaged(
        itemsPerPage: number,
        pageNumber: number,
        search?: string,
        orderBy?: string,
        orderDesc?: boolean,
        status?: IssueStatus[],
        createdByIds?: number[],
        tags?: number[],
        archiveFilter?: ArchivedIssueFilter,
        deviceId?: number,
        cancelToken?: any
    ): Promise<AxiosResponse<PagedData<Issue>>> {
        const config = {
            params: {
                itemsPerPage,
                pageNumber,
                search,
                orderBy,
                orderDesc,
                status,
                createdByIds,
                tags,
                archiveFilter,
                deviceId,
            },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<Issue>>("/issues", config);
    }

    getIssueById(issueId: number, cancelToken?: any): Promise<AxiosResponse<Issue>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<Issue>(`/issues/${issueId}`, config);
    }

    getReporters(search?: string, limit: number = 50, cancelToken?: any): Promise<AxiosResponse<Reporter[]>> {
        const config = {
            params: { search, limit },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<Reporter[]>("/issues/reporters", config);
    }

    updateIssue(issue: Issue, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.put<GenericResponse>(`/issues/${issue.issueId}`, issue, config);
    }

    patchIssue(
        issueId: number,
        issue: Partial<Issue>,
        cancelToken?: any,
        params: { resolveLinkedDevices: boolean | undefined } | undefined = undefined
    ): Promise<AxiosResponse<GenericResponse>> {
        const config = { params, cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.patch<GenericResponse>(`/issues/${issueId}`, issue, config);
    }

    archiveIssue(
        issueId: number,
        cancelToken?: any,
        params: { resolveLinkedDevices: boolean | undefined } | undefined = undefined
    ): Promise<AxiosResponse<GenericResponse>> {
        const config = { params, cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<GenericResponse>(`/issues/${issueId}/archive`, config);
    }

    restoreIssue(
        issueId: number,
        cancelToken?: any,
        params: { resolveLinkedDevices: boolean | undefined } | undefined = undefined
    ): Promise<AxiosResponse<GenericResponse>> {
        const config = { params, cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<GenericResponse>(`/issues/${issueId}/restore`, config);
    }

    deleteIssue(issueId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/issues/${issueId}`, config);
    }

    getIssueComments(
        issueId: number,
        limit?: number,
        createdBefore?: Date | string,
        createdAfter?: Date | string
    ): Promise<AxiosResponse<PagedComments>> {
        const config = {
            params: { limit, createdAfter, createdBefore },
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedComments>(`/issues/${issueId}/comments`, config);
    }

    addIssueComment(issueId: number, content: string, cancelToken?: any): Promise<AxiosResponse<Comment>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.post<Comment>(`/issues/${issueId}/comments`, { content }, config);
    }

    updateIssueComment(issueId: number, comment: Comment, cancelToken?: any): Promise<AxiosResponse<Comment>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.put<Comment>(
            `/issues/${issueId}/comments/${comment.commentId}`,
            { content: comment.content },
            config
        );
    }

    deleteComment(issueId: number, commentId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`issues/${issueId}/comments/${commentId}`, config);
    }

    getDevicesByIssuePaged(
        issueId: number,
        itemsPerPage: number,
        pageNumber: number,
        searchTerm?: string,
        orderBy?: string,
        orderDesc: boolean = false,
        status?: IssueDeviceStatus[],
        cancelToken?: any
    ): Promise<AxiosResponse<PagedData<IssueDeviceListItem>>> {
        const config = {
            params: {
                itemsPerPage: itemsPerPage,
                pageNumber: pageNumber,
                search: searchTerm,
                orderBy: orderBy,
                orderDesc: orderDesc,
                status,
            },
            cancelToken: cancelToken?.token,
        } as AxiosRequestConfig;

        return this.apiClient.get<PagedData<IssueDeviceListItem>>(`/issues/${issueId}/devices`, config);
    }

    updateIssueDeviceStatus(
        issueId: number,
        deviceId: number,
        status: IssueDeviceStatus,
        cancelToken?: any
    ): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.put<GenericResponse>(`/issues/${issueId}/devices/${deviceId}`, { status }, config);
    }

    removeDevice(issueId: number, deviceId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.delete<GenericResponse>(`/issues/${issueId}/devices/${deviceId}`, config);
    }

    addDevice(issueId: number, deviceId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;

        return this.apiClient.post<GenericResponse>(`issues/${issueId}/devices`, { deviceId }, config);
    }

    getIssueDevicesSummary(issueId: number, cancelToken?: any): Promise<AxiosResponse<IssueDevicesSummary>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<IssueDevicesSummary>(`/issues/${issueId}/devices/summary`, config);
    }

    getDeviceStats(issueId: number, cancelToken?: any): Promise<AxiosResponse<IssueDevicesStats>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<IssueDevicesStats>(`/issues/${issueId}/devices/stats`, config);
    }

    getUserWatchlist(cancelToken?: any): Promise<AxiosResponse<IssueWatcher[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<IssueWatcher[]>(`/issues/watchlist-candidates`, config);
    }

    getIssueWatchers(issueId: number, cancelToken?: any): Promise<AxiosResponse<IssueWatcher[]>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.get<IssueWatcher[]>(`/issues/${issueId}/watchlist`, config);
    }

    addIssueWatchers(issueId: number, userId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<GenericResponse>(`/issues/${issueId}/watchlist/${userId}`, {}, config);
    }

    deleteIssueWatchers(issueId: number, userId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.delete<GenericResponse>(`/issues/${issueId}/watchlist/${userId}`, config);
    }

    addMeToIssueWatchers(issueId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.post<GenericResponse>(`/issues/${issueId}/watchlist/me`, {}, config);
    }

    deleteMeFromIssueWatchers(issueId: number, cancelToken?: any): Promise<AxiosResponse<GenericResponse>> {
        const config = { cancelToken: cancelToken?.token } as AxiosRequestConfig;
        return this.apiClient.delete<GenericResponse>(`/issues/${issueId}/watchlist/me`, config);
    }
}

const issueResource = new IssueResource();
export default issueResource;
