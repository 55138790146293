<template>
  <v-menu @update:modelValue="onMenuClose">
    <template v-slot:activator="{ props }">
      <v-btn
        :loading="Boolean(loading || deleting.length)"
        :color="isWatchCurrentUser ? 'primary' : 'white'"
        variant="text"
        v-bind="props"
        class="pa-0"
        size="small"
      >
        <v-icon size="24">{{ isWatchCurrentUser ? "mdi-eye" : "mdi-eye-outline"}}</v-icon>
        <span class="ml-1">{{ watchers.length || "" }}</span>
      </v-btn>
    </template>
    <v-list min-width="250px" density="compact" class="list">
      <v-list-item v-if="!isWatchCurrentUser" @click="addCurrentUserToWatchlist">
        <v-icon class="mr-2" color="primary">mdi-eye</v-icon>
        Start watching
      </v-list-item>
      <v-list-item v-if="isWatchCurrentUser" @click="deleteCurrentUserFromWatchlist">
        <v-icon class="mr-2" color="white">mdi-eye-outline</v-icon>
        Stop watching
      </v-list-item>
      <v-divider></v-divider>

      <div class="text-subtitle-2 text-grey ml-5 mt-3 mb-1">Watching this issue</div>

      <div class="watcher-list mb-3">
        <v-hover
          v-if="watchers.length"
          v-for="{ username, userId } in watchers"
          :key="userId"
          v-slot:default="{ props, isHovering }"
        >
          <v-list-item v-bind="props" :link="canEditIssues" @click.stop.prevent>
            <div class="d-flex justify-space-between align-center">
              <div class="d-flex my-1 px-1 align-center">
                <v-icon density="compact" size="20" class="mr-2">mdi-account </v-icon>
                <v-list-item-title class="text-capitalize">{{ username }}</v-list-item-title>
              </div>
              <v-btn
                v-if="
                  (isHovering || Boolean(deleting.find((v) => v === userId))) && (canEditIssues || userId === currentUserId)
                "
                color="primary"
                icon
                variant="text"
                density="compact"
                :loading="Boolean(deleting.find((v) => v === userId))"
                @click="() => (userId === currentUserId ? deleteCurrentUserFromWatchlist() : deleteWatchers(userId))"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-list-item>
        </v-hover>

        <v-list-item v-if="!watchers.length">
          <div class="text-center">
            <v-icon density="comfortable" size="32">mdi-account-group </v-icon>
            <div class="text-caption text-medium-emphasis">No watchers yet</div>
          </div>
        </v-list-item>
      </div>
      <v-divider v-if="canEditIssues"></v-divider>
      <v-list-item v-if="canEditIssues" @click.stop.prevent :ripple="false">
        <div v-if="!showWatcherSelect" @click="showWatchSelect">
          <v-icon color="primary">mdi-plus</v-icon>
          <span class="ml-2">Add watchers</span>
        </div>
        <div v-if="showWatcherSelect">
          <v-autocomplete
            v-model="selected"
            ref="watchlistSelect"
            @update:modelValue="clearSelection"
            v-model:search="search"
            class="nt-2"
            :items="userWatchlist.filter((v) => !watchers.find(({ userId }) => userId === v.userId))"
            :loading="usersLoading"
            clearable
            hide-no-data
            return-object
            item-value="userId"
            item-title="username"
            hide-details
            density="compact"
            attach
            :placeholder="userWatchlist.length === watchers.length ? 'No users available' : ''"
            :disabled="userWatchlist.length === watchers.length || loading"
          >
            <template v-slot:prepend-inner>
              <v-icon class="mb-1" size="20">mdi-account</v-icon>
            </template>
            <template v-slot:item="{ props, item }">
              <v-list-item 
                v-bind="{ ...props, title: '' }" 
                @click.stop.prevent="() => item.raw.userId === currentUserId ? addCurrentUserToWatchlist() : addWatchers(item.raw.userId)"
              >
                <span class="text-capitalize">{{ item.raw.username }} </span>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { ref, computed, watch, nextTick } from "vue";
import issueResource from "@/resources/IssueResource";
import Issue from "@/types/Issue";
import IssueWatcher from "@/types/IssueWatcher";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import { VAutocomplete } from "vuetify/lib/components/index.mjs";

const props = withDefaults(defineProps<{ issue: Issue | null }>(), {
  issue: null,
});

const selected = ref(null);
const search = ref("");
const clearSelection = async () => {
  await nextTick();
  selected.value = null;
  search.value = "";
};

const showWatcherSelect = ref(false);
const currentUserId = computed(() => userProfileService.currentUser?.userId);
const isWatchCurrentUser = computed(() => watchers.value.find(({ userId }) => userId === currentUserId.value));

const watchers = ref<IssueWatcher[]>([]);
const userWatchlist = ref<IssueWatcher[]>([]);
const loading = ref(false);
const deleting = ref<number[]>([]);
const usersLoading = ref(false);

const watchlistSelect = ref<VAutocomplete | null>(null);

const canEditIssues = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.EditIssues);
});

const addCurrentUserToWatchlist = async () => {
  if (!props.issue?.issueId) return;

  loading.value = true;
  try {
    await issueResource.addMeToIssueWatchers(props.issue.issueId);
    getIssueWatchers();
  } catch (e) {
    issueResource.defaultErrorHandler(e);
  }

  loading.value = false;
  showWatcherSelect.value = false;
};

const deleteCurrentUserFromWatchlist = async () => {
  if (!props.issue?.issueId) return;
  if (currentUserId.value) deleting.value.push(currentUserId.value);

  try {
    await issueResource.deleteMeFromIssueWatchers(props.issue.issueId);
    watchers.value = watchers.value.filter((w) => w.userId !== currentUserId.value);
    getIssueWatchers();
  } catch (e) {
    issueResource.defaultErrorHandler(e);
  }

  deleting.value = deleting.value.filter((deletingUserId) => deletingUserId !== currentUserId.value);
  showWatcherSelect.value = false;
};

const deleteWatchers = async (userId: number) => {
  if (!props.issue?.issueId || !userId || !canEditIssues.value) return;
  deleting.value.push(userId);
  try {
    await issueResource.deleteIssueWatchers(props.issue.issueId, userId);
    watchers.value = watchers.value.filter((w) => w.userId !== userId);
    getIssueWatchers();
  } catch (e) {
    issueResource.defaultErrorHandler(e);
  }

  deleting.value = deleting.value.filter((deletingUserId) => deletingUserId !== userId);
};

const addWatchers = async (userId: number) => {
  if (!props.issue?.issueId || !userId || !canEditIssues.value) return;
  loading.value = true;
  try {
    await issueResource.addIssueWatchers(props.issue.issueId, userId);
    getIssueWatchers();
  } catch (e) {
    issueResource.defaultErrorHandler(e);
  }

  loading.value = false;
  showWatcherSelect.value = false;
};

const getIssueWatchers = async () => {
  if (!props.issue?.issueId) return;
  loading.value = true;
  try {
    const { data } = await issueResource.getIssueWatchers(props.issue.issueId);
    watchers.value = data;
  } catch (e) {
    issueResource.defaultErrorHandler(e);
  }

  loading.value = false;
};

const showWatchSelect = async () => {
  if (!canEditIssues.value) return;
  showWatcherSelect.value = true;
  getUserWatchlist();

  await nextTick();
  watchlistSelect?.value?.focus();
};

const getUserWatchlist = async () => {
  if (!canEditIssues.value) return;
  usersLoading.value = true;

  try {
    if(userWatchlist.value.length === 0) {
      const { data } = await issueResource.getUserWatchlist();
      userWatchlist.value = data;
    }
  } catch (e) {
    issueResource.defaultErrorHandler(e);
  }

  usersLoading.value = false;
};

const onMenuClose = (newState: boolean) => {
  if (!newState && showWatcherSelect.value) showWatcherSelect.value = false;
};

watch(
  () => props.issue,
  () => {
    if (props.issue?.issueId) getIssueWatchers();
  },
  { immediate: true }
);
</script>

<style scoped>
.watcher-list {
  max-height: calc(100vh - 250px);
  overflow: auto;
  width: 100%;
}
</style>
